section {
  padding-block: 5rem;
}

.diagonal {
  --skew-angle: -5deg;
  --background: linear-gradient(45deg, #4f48ed, #8681fe, #00d4ff);
  position: relative;
  isolation: isolate;
}

.video-layer {
  --background: linear-gradient(45deg, #4f48ed, #8681fe, #00d4ff);
  background: var(--background);
  clip-path: polygon(0 0, 100% calc(5svw), 100% 100%, 0% 100%);
}

.diagonal::after {
  content: "";
  background: var(--background);
  position: absolute;
  z-index: -1;
  inset: 0;
  transform: skewY(var(--skew-angle));
}

#p-statement {
  background: rgb(79, 70, 229);
  background: linear-gradient(
    45deg,
    rgba(79, 70, 229, 1) 0%,
    rgba(129, 140, 248, 1) 28%,
    rgba(199, 210, 254, 1) 100%
  );
}

details > summary {
  list-style: none;
  cursor: pointer;
}

details > summary::-webkit-details-marker {
  display: none;
}

.custom-lt {
  text-underline-offset: -0.28rem;
  text-decoration-skip-ink: none;
}

.phone-graphic {
  --phoneBorderRadius: 36px;
  --phoneScreenBorderRadius: 29px;
  --phoneWidth: 264px;
  --phoneHeight: 533px;
  --phoneGraphicShadow: 0 50px 100px -20px rgba(50,50,93,0.25),0 30px 60px -30px rgba(0,0,0,0.3),inset 0 -2px 6px 0 rgba(10,37,64,0.35);
  width: var(--phoneWidth);
  height: var(--phoneHeight);
  padding: 8px;
  border-radius: var(--phoneBorderRadius);
  background: #f6f9fc;
  box-shadow: var(--phoneGraphicShadow);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.phone-graphic .screen {
  position: relative;
  height: 100%;
  border-radius: var(--phoneScreenBorderRadius);
  -webkit-mask-image: -webkit-radial-gradient(#fff,#000);
}